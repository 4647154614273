import { useEffect, useRef, useState } from "react";
import { Iller, ilceler, SiteUrl } from "./constant";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import ModalPage from "./modal";
import axios from "axios";
import { API_URL } from "./config";
import { Progress } from "antd";
import { useForm, Controller } from "react-hook-form";

function App() {
  const [quantity, setQuantity] = useState(1);
  const [modal, setModal] = useState(false);

  const [message, setMessage] = useState("");
  const formRef = useRef(null);
  const scrollRef = useRef(null);

  const [category, setCategory] = useState([]);
  const [mycat, setMyCat] = useState(null);
  const [productDatas, setProductDatas] = useState(null);
  const [productData, setProductData] = useState(null);

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      surname: "",
      email: "",
      identityNumber: "",
      not: "",
      tel: "",
      zipCode: "",
      adres: "",
      category: "Bireysel",
      firmaName: "",
      vergiDairesi: "",
      vergiNo: "",
      efatura: false,
      check: false,
    },
  });

  const [bilgiler, setBilgiler] = useState({
    city: "ADANA",
    sehirid: 1,
    ilce: "ALADAĞ",
  });
  const ILILCE = ilceler.filter(
    (item) => item.il_id === bilgiler.sehirid.toString()
  );

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(API_URL + "/api/getproductone?slug=kure")
        .then((res) => {
          console.log(res.data);
          setCategory(res.data.category.SubCategory);
          setMyCat(res.data.category.SubCategory[0]);
          setProductDatas(res.data.products);
          setProductData(res.data.products[0]);
        })
        .catch((error) => {
          console.log(error);
          alert("Hata Oluştu");
        });
    };
    fetchData();
  }, []);

  const [loading, setLoading] = useState(false);
  const [percent, setPercent] = useState(0);
  const onSubmit = async (data) => {
    if (quantity !== "" && quantity >= 1) {
      const user = null;
      const userInfo = {
        data: {
          name: data.name,
          surname: data.surname,
          email: data.email,
          identityNumber: data.identityNumber,
          not: data.not,
          tel: data.tel,
        },
        bill: {
          id: 1,
          adressname: null,
          address: data.adres + " " + bilgiler.ilce,
          country: "Türkiye",
          city: bilgiler.city,
          zipCode: data.zipCode,
          category: data.category,
          firmaName: data.firmaName,
          vergiDairesi: data.vergiDairesi,
          vergiNo: data.vergiNo,
          efatura:
            data.efatura === "true" || data.efatura === true ? true : false,
        },
        send: {
          id: 1,
          adressname: null,
          address: data.adres + " " + bilgiler.ilce,
          country: "Türkiye",
          city: bilgiler.city,
          zipCode: data.zipCode,
          category: data.category,
          firmaName: data.firmaName,
          vergiDairesi: data.vergiDairesi,
          vergiNo: data.vergiNo,
          efatura:
            data.efatura === "true" || data.efatura === true ? true : false,
        },
      };
      const basket = [];

      if (1 <= productData.stock) {
        const basdata = {
          id: productData.id,
          name: productData.name,
          category: mycat.name,
          price: productData.price,
          inprice: productData.inprice,
          indirim: productData.indirim,
          quantity: quantity,
          image: productData.images[0].imageurl,
        };
        basket.push(basdata);
      } else {
        alert("Stoklarda Kalmadı.");
        return;
      }

      const formData = { user, userInfo, basket };
      try {
        const response = await axios.post(
          API_URL + "/api/iyzico-order-send",
          formData
        );

        if (response.data) {
          const res = response.data;

          if (res?.status === "success") {
            const checkoutFormContent = res.checkoutFormContent;

            if (checkoutFormContent) {
              setLoading(true);
              const div = document.createElement("div");
              div.innerHTML = checkoutFormContent;
              formRef.current.appendChild(div);

              // Script'i çalıştırmak için script içeriğini ayrıştır ve çalıştır
              const scripts = div.getElementsByTagName("script");

              for (let i = 0; i < scripts.length; i++) {
                const script = document.createElement("script");
                script.type = "text/javascript";
                script.text = scripts[i].text;
                document.head.appendChild(script);
              }
              setMessage("");
            }
          } else {
            setMessage(res.message);
            return;
          }
        }
      } catch (error) {
        setLoading(false);
        if (error.response && error.response.status === 500)
          alert("Server Hatası Oluştu");
      }
    }
  };
  useEffect(() => {
    const setLoad = async () => {
      if (loading) {
        for (let i = 0; i < 25; i++) {
          await new Promise((resolve) => {
            setTimeout(() => {
              setPercent((prev) => {
                const nextPercent = prev + 4;
                return nextPercent > 100 ? 100 : nextPercent;
              });
              resolve();
            }, 100);
          });
        }
        if (scrollRef.current) {
          scrollRef.current.scrollIntoView({
            block: "start",
            behavior: "smooth",
          });
        }
        setLoading(false);
      }
    };
    setLoad();
  }, [loading]);

  useEffect(() => {
    // const header = document.querySelector(".header-section");
    const headermenu = document.querySelector(".header-menu");

    const isSticky = () => {
      const scrollTop = window.scrollY;
      if (scrollTop >= 125) {
        headermenu.classList.add("is-sticky");
      } else {
        headermenu.classList.remove("is-sticky");
      }
    };

    window.addEventListener("scroll", isSticky);

    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const AddressCategory = watch("category");

  return (
    <div className="w-full h-full flex flex-col items-center justify-start bg-[#141C25]">
      <Modal open={modal} onClose={() => setModal(!modal)} center>
        <div className="px-2 py-3">
          <h2>1. TARAFLAR</h2>
          <p>
            İşbu Sözleşme aşağıdaki taraflar arasında aşağıda belirtilen hüküm
            ve şartlar çerçevesinde imzalanmıştır.
          </p>
          <p>
            <strong>A. 'ALICI';</strong> (sözleşmede bundan sonra "ALICI" olarak
            anılacaktır)
          </p>
          <p>
            <strong>B. 'SATICI';</strong> (sözleşmede bundan sonra "SATICI"
            olarak anılacaktır)
            <br />
            AD-SOYAD: MAY PLASTİK
            <br />
            ADRES: Tatlıcak Mahallesi Gürçınar Sokak No:60 Vatan Sanayi Sitesi
            Karatay - KONYA
            <br />
            İş bu sözleşmeyi kabul etmekle ALICI, sözleşme konusu siparişi
            onayladığı takdirde sipariş konusu bedeli ve varsa kargo ücreti,
            vergi gibi belirtilen ek ücretleri ödeme yükümlülüğü altına
            gireceğini ve bu konuda bilgilendirildiğini peşinen kabul eder.
          </p>

          <h2>2. TANIMLAR</h2>
          <p>
            İşbu sözleşmenin uygulanmasında ve yorumlanmasında aşağıda yazılı
            terimler karşılarındaki yazılı açıklamaları ifade edeceklerdir.
          </p>
          <p>
            <strong>BAKAN:</strong> Gümrük ve Ticaret Bakanı'nı,
            <br />
            <strong>BAKANLIK:</strong> Gümrük ve Ticaret Bakanlığı'nı,
            <br />
            <strong>KANUN:</strong> 6502 sayılı Tüketicinin Korunması Hakkında
            Kanun'u,
            <br />
            <strong>YÖNETMELİK:</strong> Mesafeli Sözleşmeler Yönetmeliği'ni
            (RG:27.11.2014/29188)
            <br />
            <strong>HİZMET:</strong> Bir ücret veya menfaat karşılığında yapılan
            ya da yapılması taahhüt edilen mal sağlama dışındaki her türlü
            tüketici işleminin konusunu,
            <br />
            <strong>SATICI:</strong> Ticari veya mesleki faaliyetleri kapsamında
            tüketiciye mal sunan veya mal sunan adına veya hesabına hareket eden
            şirketi,
            <br />
            <strong>ALICI:</strong> Bir mal veya hizmeti ticari veya mesleki
            olmayan amaçlarla edinen, kullanan veya yararlanan gerçek ya da
            tüzel kişiyi,
            <br />
            <strong>SİTE:</strong> SATICI'ya ait internet sitesini,
            <br />
            <strong>SİPARİŞ VEREN:</strong> Bir mal veya hizmeti SATICI'ya ait
            internet sitesi üzerinden talep eden gerçek ya da tüzel kişiyi,
            <br />
            <strong>TARAFLAR:</strong> SATICI ve ALICI'yı,
            <br />
            <strong>SÖZLEŞME:</strong> SATICI ve ALICI arasında akdedilen işbu
            sözleşmeyi,
            <br />
            <strong>MAL:</strong> Alışverişe konu olan taşınır eşyayı ve
            elektronik ortamda kullanılmak üzere hazırlanan yazılım, ses,
            görüntü ve benzeri gayri maddi malları ifade eder.
          </p>

          <h2>3. KONU</h2>
          <p>
            İşbu Sözleşme, ALICI'nın, SATICI'ya ait internet sitesi üzerinden
            elektronik ortamda siparişini verdiği aşağıda nitelikleri ve satış
            fiyatı belirtilen ürünün satışı ve teslimi ile ilgili olarak 6502
            sayılı Tüketicinin Korunması Hakkında Kanun ve Mesafeli Sözleşmelere
            Dair Yönetmelik hükümleri gereğince tarafların hak ve
            yükümlülüklerini düzenler.
          </p>
          <p>
            Listelenen ve sitede ilan edilen fiyatlar satış fiyatıdır. İlan
            edilen fiyatlar ve vaatler güncelleme yapılana ve değiştirilene
            kadar geçerlidir. Süreli olarak ilan edilen fiyatlar ise belirtilen
            süre sonuna kadar geçerlidir.
          </p>

          <h2>4. SATICI BİLGİLERİ</h2>
          <p>Ünvanı: MAY PLASTİK</p>
        </div>
      </Modal>
      <Modal
        open={loading}
        center
        styles={{
          modal: {
            background: "transparent",
            boxShadow: "none",
            padding: 0,
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        }}
        showCloseIcon={false}
      >
        <div className="w-full h-full flex items-center justify-center">
          <Progress type="circle" percent={percent} size={80} />
        </div>
      </Modal>

      <div className="header-menu w-full max-w-[1000px] mx-auto hidden">
        <div className="w-full flex lg:items-center lg:justify-center flex-nowrap pb-3 gap-3 lg:flex-wrap px-8 overflow-x-scroll  lg:overflow-hidden">
          {SiteUrl?.filter((data) => data.name !== "Küre")?.map((item) => {
            return (
              <a
                key={item.id}
                href={item.url}
                className={`w-[84px] h-[84px] aspect-square text-inherit rounded-full flex items-center justify-center bg-gradient-to-r from-[#F0B944] via-purple-600  to-[#F0B944] bg-[length:110%] bg-[50%_50%]`}
              >
                <div
                  className={`w-[81px] h-[81px] bg-[#24003e]/95 rounded-full flex items-center justify-center shadow-xl`}
                >
                  <div
                    className={`w-[72px] h-[72px] rounded-full  flex items-center justify-center bg-purple-900/75`}
                  >
                    <span className="text-sm font-bold text-slate-200 text-center">
                      {item.name}
                    </span>
                  </div>
                </div>
              </a>
            );
          })}
        </div>
      </div>

      <a href="#odeme">
        <img src="/resim/01.webp" alt="Image1" className="object-contain" />
      </a>

      <video
        src="/resim/video.mp4"
        loop
        muted
        autoPlay
        controls={false}
        style={{ width: "1000px", height: "auto" }}
      />
      <img
        id="odeme"
        src="/resim/siparis.webp"
        alt="Image1"
        className="object-contain"
      />
      <div className="w-full max-w-[1000px] h-full flex flex-col items-start justify-start bg-white px-2 md:px-1">
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          <div className="md:w-3/4 flex flex-col pt-2 space-y-2">
            <div className="font-semibold text-sm">
              Lütfen aşağıdaki formu eksiksiz doldurunuz.
            </div>
            {productDatas
              ?.filter((data) => data?.subCategoryId === mycat?.id)
              .map((item) => {
                const formattedValue =
                  item?.indirim === true
                    ? new Intl.NumberFormat("tr-TR").format(
                        quantity * item.inprice
                      )
                    : new Intl.NumberFormat("tr-TR").format(
                        quantity * item.price
                      );
                return (
                  <div
                    key={item.id}
                    className="flex space-x-3 bg-gray-100 px-3 py-1 border rounded-lg cursor-pointer"
                    onClick={() => setProductData(item)}
                  >
                    <input
                      type="radio"
                      id="radio"
                      name="radio1"
                      checked={productData?.id === item?.id}
                      onChange={() => setProductData(item)}
                    />
                    <p className="text-sm cursor-pointer">
                      {quantity} Adet {item?.name} -{" "}
                      <span className="font-bold">{formattedValue} TL</span> +
                      Kargo Bedava
                    </p>
                  </div>
                );
              })}
          </div>
          <div className="md:w-3/4 flex flex-col md:flex-row items-start justify-start md:space-x-12 mt-2">
            <div className="w-1/4 md:w-1/12 flex flex-col pt-3 space-y-1">
              <div className="text-sm ">ADET</div>
              <input
                type="number"
                className="w-full pl-2 p-1 border-2 rounded-md bg-white"
                value={quantity}
                min={1}
                max={1000}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === "" || value >= 1) {
                    setQuantity(value);
                  }
                }}
              />
            </div>
            <div className="w-3/4 md:w-5/12 flex flex-col pt-3 space-y-1">
              <div className="text-sm ">Çeşitleri</div>
              <select
                name="Çeşit"
                className="w-full md:pl-2 h-10 md:h-auto bg-gray-100 px-3 py-1 border rounded-lg"
                value={mycat?.id}
                onChange={(e) => {
                  const catdata =
                    category.find(
                      (item) => item.id === parseInt(e.target.value)
                    ) || null;
                  setMyCat(catdata);
                  const pdata =
                    productDatas.find(
                      (item) => item.subCategoryId === catdata.id
                    ) || null;

                  setProductData(pdata);
                }}
              >
                {category?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="md:w-3/4 flex space-x-4">
            <div className="w-3/4 flex flex-col pt-3 space-y-1">
              <div className="text-sm ">
                Adınız{" "}
                {errors.name && (
                  <span className="text-red-500">{errors.name.message}</span>
                )}
              </div>
              <input
                type="text"
                className="w-full pl-2 h-10 md:h-auto border-2 rounded-md bg-white"
                maxLength={100}
                {...register("name", {
                  required: "Zorunlu !",
                  maxLength: {
                    value: 100,
                    message: "En fazla 100 karakter girebilirsiniz",
                  },
                })}
              />
            </div>
            <div className="w-3/4 flex flex-col pt-3 space-y-1">
              <div className="text-sm ">
                Soyadınız{" "}
                {errors.surname && (
                  <span className="text-red-500">{errors.surname.message}</span>
                )}
              </div>
              <input
                type="text"
                className="w-full pl-2 h-10 md:h-auto border-2 rounded-md bg-white"
                {...register("surname", {
                  required: "Zorunlu !",
                  maxLength: {
                    value: 100,
                    message: "En fazla 100 karakter girebilirsiniz",
                  },
                })}
              />
            </div>
          </div>
          <div className="md:w-3/4 flex space-x-4">
            <div className="w-3/4 flex flex-col pt-3 space-y-1">
              <div className="text-sm ">
                Telefon Numaranız{" "}
                {errors.tel && (
                  <span className="text-red-500">{errors.tel.message}</span>
                )}
              </div>
              <input
                type="text"
                className="w-full pl-2 h-10 md:h-auto border-2 rounded-md bg-white"
                maxLength={17}
                {...register("tel", {
                  required: "Zorunlu !",
                  pattern: {
                    value: /^\d+$/,
                    message: "Sadece rakam girilmelidir",
                  },
                  maxLength: {
                    value: 17,
                    message: "En fazla 17 karakter girebilirsiniz",
                  },
                })}
              />
            </div>
            <div className="w-3/4 flex flex-col pt-3 space-y-1">
              <div className="text-sm ">
                Email{" "}
                {errors.email && (
                  <span className="text-red-500">{errors.email.message}</span>
                )}
              </div>
              <input
                type="email"
                className="w-full pl-2 h-10 md:h-auto border-2 rounded-md bg-white"
                {...register("email", {
                  required: "Zorunlu !",
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: "Geçerli bir email adresi girin",
                  },
                })}
              />
            </div>
          </div>
          <div className="md:w-3/4 flex space-x-4">
            <div className="w-3/4 flex flex-col pt-3 space-y-1">
              <div className="text-sm ">
                TC Kimlik No{" "}
                {errors.identityNumber && (
                  <span className="text-red-500">
                    {errors.identityNumber.message}
                  </span>
                )}
              </div>
              <input
                type="text"
                className="w-full pl-2 h-10 md:h-auto border-2 rounded-md bg-white"
                maxLength={11}
                {...register("identityNumber", {
                  required: "Zorunlu !",
                  pattern: {
                    value: /^\d{11}$/,
                    message: "Sadece 11 rakam giriniz",
                  },
                })}
              />
            </div>
            <div className="w-3/4 flex flex-col pt-3 space-y-1">
              <div className="text-sm ">
                Not{" "}
                {errors.not && (
                  <span className="text-red-500">{errors.not.message}</span>
                )}
              </div>
              <input
                type="text"
                className="w-full pl-2 h-10 md:h-auto border-2 rounded-md bg-white"
                maxLength={400}
                {...register("not", {
                  maxLength: {
                    value: 400,
                    message: "En fazla 400 karakter girebilirsiniz",
                  },
                })}
              />
            </div>
          </div>
          <div className="md:w-3/4 flex space-x-4 mt-5">
            <div className="w-full flex items-center justify-center px-6">
              <Controller
                name="category"
                control={control}
                render={({ field }) => (
                  <div
                    className={`w-full h-12 flex items-center justify-center py-4 px-6 shadow-lg rounded-l-lg cursor-pointer bg-[#F5F5F5] transition-all ease-linear ${
                      field.value === "Bireysel"
                        ? "border-2 border-purple-700"
                        : "border"
                    }`}
                    onClick={() => field.onChange("Bireysel")}
                  >
                    Bireysel
                  </div>
                )}
              />
              <Controller
                name="category"
                control={control}
                render={({ field }) => (
                  <div
                    className={`w-full h-12 flex items-center justify-center py-4 px-6 shadow-lg rounded-r-lg cursor-pointer bg-[#F5F5F5] transition-all ease-linear ${
                      field.value === "Kurumsal"
                        ? "border-2 border-purple-700"
                        : "border"
                    }`}
                    onClick={() => field.onChange("Kurumsal")}
                  >
                    Kurumsal
                  </div>
                )}
              />
            </div>
          </div>
          <div className="md:w-3/4 flex flex-col pt-3 space-y-1">
            <div className="text-sm ">
              Teslimat Adresi{" "}
              {errors.adres && (
                <span className="text-red-500">{errors.adres.message}</span>
              )}
            </div>
            <input
              type="text"
              className="w-full pl-2 h-10 md:h-auto border-2 rounded-md bg-white"
              {...register("adres", {
                required: "Zorunlu !",
                pattern: {
                  value: /^[^<>]*$/,
                  message: "Özel karakterler (< ve >) kullanılamaz",
                },
              })}
            />
          </div>
          {AddressCategory === "Kurumsal" && (
            <>
              <div className="md:w-3/4 flex space-x-4">
                <div className="w-3/4 flex flex-col pt-3 space-y-1">
                  <div className="text-sm ">
                    Firma Adı{" "}
                    {errors.firmaName && (
                      <span className="text-red-500">
                        {errors.firmaName.message}
                      </span>
                    )}
                  </div>
                  <input
                    type="text"
                    className="w-full pl-2 h-10 md:h-auto border-2 rounded-md bg-white"
                    maxLength={60}
                    {...register("firmaName")}
                  />
                </div>
                <div className="w-3/4 flex flex-col pt-3 space-y-1">
                  <div className="text-sm ">
                    Vergi Dairesi{" "}
                    {errors.vergiDairesi && (
                      <span className="text-red-500">
                        {errors.vergiDairesi.message}
                      </span>
                    )}
                  </div>
                  <input
                    type="text"
                    className="w-full pl-2 h-10 md:h-auto border-2 rounded-md bg-white"
                    maxLength={60}
                    {...register("vergiDairesi")}
                  />
                </div>
              </div>
              <div className="md:w-3/4 flex space-x-4">
                <div className="w-3/4 flex flex-col pt-3 space-y-1">
                  <div className="text-sm ">
                    Vergi No{" "}
                    {errors.vergiNo && (
                      <span className="text-red-500">
                        {errors.vergiNo.message}
                      </span>
                    )}
                  </div>
                  <input
                    type="text"
                    className="w-full pl-2 h-10 md:h-auto border-2 rounded-md bg-white"
                    maxLength={60}
                    {...register("vergiNo")}
                  />
                </div>
                <div className="w-3/4 pt-3 ">
                  <div className="w-full h-full flex items-center justify-center space-x-3 px-3 mt-3">
                    <input
                      type="checkbox"
                      name="checkbox1"
                      className="w-4 h-4 rounded-xl"
                      {...register("efatura")}
                    />
                    <label className="text-nowrap font-medium">
                      E-Fatura Mükellefiyim
                    </label>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="md:w-3/4 flex space-x-4">
            <div className="w-3/4 flex flex-col pt-3 space-y-1">
              <div className="text-sm ">Şehir Seçiniz</div>
              <select
                name="sehir"
                className="w-full pl-2 h-10 md:h-auto bg-gray-100 px-3 py-1 border rounded-lg"
                value={bilgiler.sehir}
                onChange={(e) => {
                  const id = Iller.find(
                    (item) => item.name === e.target.value
                  ).id;
                  const ilce = ilceler.filter(
                    (item) => item.il_id === id.toString()
                  )[0].name;
                  setBilgiler((prev) => ({
                    ...prev,
                    city: e.target.value,
                    sehirid: id,
                    ilce: ilce,
                  }));
                }}
              >
                {Iller.map((item) => (
                  <option key={item.id} id={item.id} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-3/4 flex flex-col pt-3 space-y-1">
              <div className="text-sm ">İlçe Seçiniz</div>
              <select
                name="ilce"
                className="w-full pl-2 h-10 md:h-auto bg-gray-100 px-3 py-1 border rounded-lg"
                value={bilgiler.ilce}
                onChange={(e) => {
                  setBilgiler((prev) => ({
                    ...prev,
                    ilce: e.target.value,
                  }));
                }}
              >
                {ILILCE.map((item) => (
                  <option key={item.id} id={item.id} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="md:w-3/4 flex flex-col pt-3 space-y-1">
            <div className="text-sm ">
              Posta Kodu{" "}
              {errors.zipCode && (
                <span className="text-red-500">{errors.zipCode.message}</span>
              )}
            </div>
            <input
              type="text"
              className="w-full pl-2 h-10 md:h-auto border-2 rounded-md bg-white"
              maxLength={7}
              {...register("zipCode", {
                required: "Zorunlu !",
                pattern: {
                  value: /^\d{1,7}$/,
                  message: "Posta kodu en fazla 7 rakamdan oluşmalıdır",
                },
              })}
            />
          </div>
          <div className="w-full flex flex-col space-y-1 py-2">
            <div className="w-full">
              {errors.check && (
                <p className="text-red-500 text-sm">{errors.check.message}</p>
              )}
            </div>
            <div className="flex space-x-2">
              <input
                type="checkbox"
                id="checkbox"
                name="check"
                {...register("check", {
                  required: "Okudum ve Kabul Ediyorum'a tıklayın",
                })}
              />
              <p className="text-sm  font-semibold">
                <span
                  className="text-blue-600 cursor-pointer"
                  onClick={() => setModal(!modal)}
                >
                  Mesafeli Satış Sözleşme
                </span>
                'sini okudum ve kabul ediyorum.
              </p>
            </div>
          </div>
          <div className="w-full">
            <button
              className="w-full h-12 md:h-auto flex items-center justify-center px-4 py-2 bg-slate-900 text-slate-100 rounded-lg"
              type="submit"
            >
              Siparişi Gönder
            </button>
          </div>
          <ModalPage />
        </form>
      </div>

      <img
        src="/resim/odeme.webp"
        alt="Image1"
        className="object-contain"
        ref={scrollRef}
      />
      <div className="w-full max-w-[1000px] h-full bg-white">
        <div className="w-full h-full">
          <div className="text-lg text-center text-red-600">
            {message && message}
          </div>
          <div
            id="iyzipay-checkout-form"
            class="responsive"
            ref={formRef}
          ></div>
        </div>
      </div>

      <img src="/resim/alt.webp" alt="Image1" className="object-contain" />
    </div>
  );
}

export default App;
